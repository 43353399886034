<template>
  <div id="report-container">
    <div>
      <div
        id="left-bar"
        style="position: fixed; width: 250px; height: 100%; overflow: auto"
      >
        <el-collapse value="1">
          <el-collapse-item title="数据集" name="1">
            <template slot="title">
              <div class="collapse-title">
                数据集
                <el-dropdown-ex
                  trigger="click"
                  :stopPropagationOnClick="true"
                  @command="handleCommand"
                >
                  <span
                    class="el-dropdown-link"
                    style="font-size: 12px; color: #1890ff"
                  >
                    <i class="el-icon-plus"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="makeCommand('realTimeModel')"
                      >实时数据</el-dropdown-item
                    >
                    <el-dropdown-item :command="makeCommand('aextractModel')"
                      >抽取数据</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown-ex>
              </div>
            </template>
            <el-tree
              ref="tree"
              :data="datasourceTree"
              :props="defaultProps"
              node-key="id"
              :highlight-current="true"
            >
              <div
                slot-scope="{ node, data }"
                @dblclick="dbclick(data)"
                @dragstart="handleDragStart(data)"
                :class="
                  data.metas && data.children
                    ? 'custom-tree-node tree-node-root'
                    : 'custom-tree-node tree-node-leaf'
                "
              >
                <img
                  v-if="data.type == '2'"
                  :src="imgs.folder"
                  class="custom-tree-node-img"
                />
                <img
                  v-else-if="data.type == '3' || data.type == '4'"
                  :src="imgs.table"
                  class="custom-tree-node-img"
                />
                <div
                  class="el-tree-node__label custom-tree-node-label"
                  :title="node.label"
                >
                  {{ node.label }}
                </div>
                <div
                  v-if="
                    data.type == '2' || data.type == '3' || data.type == '4'
                  "
                  class="custom-tree-node-button"
                >
                  <el-dropdown-ex
                    trigger="click"
                    :stopPropagationOnClick="true"
                    @command="handleCommand"
                    @visible-change="
                      (e) => handleDropdownVisibleChange(e, data)
                    "
                  >
                    <span class="el-dropdown-link">
                      <i class="el-icon-more"></i>&nbsp;
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        icon="el-icon-refresh"
                        :command="makeCommand('refresh', data)"
                        >刷新</el-dropdown-item
                      >
                      <el-dropdown-item
                        icon="el-icon-delete"
                        :command="makeCommand('delete', data)"
                        >删除</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown-ex>
                </div>
              </div>
            </el-tree>
          </el-collapse-item>
          <el-collapse-item title="参数信息" name="2">
            <template slot="title">
              <div class="collapse-title">参数信息</div>
            </template>
            <el-tree
              :data="paramList"
              :props="defaultProps"
              style="overflow: auto"
            >
              <div slot-scope="item" class="tree-node">
                {{ item.data.label }}
              </div>
            </el-tree>
          </el-collapse-item>
          <el-collapse-item title="基本设置" name="3">
            <template slot="title">
              <div class="collapse-title">基本设置</div>
            </template>
            <div class="collapse-content">
              <el-form label-position="top" label-width="80px" size="mini">
                <el-form-item label="名称">
                  <el-input v-model="template.name" readonly="readonly" />
                </el-form-item>
                <el-form-item label="背景图片">
                  <file-upload
                    v-model="backgroundImage.url"
                    type="form"
                    title="背景图片"
                    placeholder="背景图片"
                    accept="image/png,image/jpeg"
                    @change="handleBackgroundImageChange"
                  />
                </el-form-item>
                <el-form-item label="背景图片宽度">
                  <el-input-number
                    v-model="backgroundImage.width"
                    placeholder="背景图片宽度"
                    controls-position="right"
                    @change="handleBackgroundImageChange"
                  />
                </el-form-item>
                <el-form-item label="背景图片高度">
                  <el-input-number
                    v-model="backgroundImage.height"
                    placeholder="背景图片高度"
                    controls-position="right"
                    @change="handleBackgroundImageChange"
                  />
                </el-form-item>
                <el-form-item label="行数">
                  <el-input-number
                    v-model="rowLen"
                    placeholder="行数"
                    controls-position="right"
                    @change="handleRowLenChange"
                  />
                </el-form-item>
                <el-form-item label="列数">
                  <el-input-number
                    v-model="colLen"
                    placeholder="列数"
                    controls-position="right"
                    @change="handleColLenChange"
                  />
                </el-form-item>
                <el-form-item label="行高">
                  <el-input-number
                    v-model="rowHeight"
                    placeholder="行高"
                    controls-position="right"
                    @change="handleRowHeightChange"
                  />
                </el-form-item>
                <el-form-item label="列宽">
                  <el-input-number
                    v-model="colWidth"
                    placeholder="列宽"
                    controls-position="right"
                    @change="handleColWidthChange"
                  />
                </el-form-item>
              </el-form>
            </div>
          </el-collapse-item>
          <el-collapse-item title="打印设置" name="4">
            <template slot="title">
              <div class="collapse-title">打印设置</div>
            </template>
            <div class="collapse-content">
              <el-form label-position="top" label-width="80px" size="mini">
                <el-form-item label="显示纸张分割线">
                  <el-select
                    v-model="showPrintGrid"
                    placeholder="请选择"
                    @change="handleShowPrintGridChange"
                  >
                    <el-option label="显示" :value="true"> </el-option>
                    <el-option label="不显示" :value="false"> </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="纸张大小">
                  <el-select
                    v-model="printSetting.size"
                    placeholder="请选择"
                    @change="handlePrintSettingChange"
                  >
                    <el-option label="A3" value="A3"> </el-option>
                    <el-option label="A4" value="A4"> </el-option>
                    <el-option label="A5" value="A5"> </el-option>
                    <el-option label="B4" value="B4"> </el-option>
                    <el-option label="B5" value="B5"> </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="纸张方向">
                  <el-select
                    v-model="printSetting.orientation"
                    placeholder="请选择"
                    @change="handlePrintSettingChange"
                  >
                    <el-option label="横向" value="landscape"> </el-option>
                    <el-option label="纵向" value="portrait"> </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="左边距（厘米）">
                  <el-input-number
                    v-model="printSetting.paddingLeft"
                    placeholder="左边距"
                    controls-position="right"
                    :precision="2"
                    :step="0.01"
                    @change="handlePrintSettingChange"
                  />
                </el-form-item>
                <el-form-item label="右边距（厘米）">
                  <el-input-number
                    v-model="printSetting.paddingRight"
                    placeholder="右边距"
                    controls-position="right"
                    :precision="2"
                    :step="0.01"
                    @change="handlePrintSettingChange"
                  />
                </el-form-item>
                <el-form-item label="上边距（厘米）">
                  <el-input-number
                    v-model="printSetting.paddingTop"
                    placeholder="上边距"
                    controls-position="right"
                    :precision="2"
                    :step="0.01"
                    @change="handlePrintSettingChange"
                  />
                </el-form-item>
                <el-form-item label="下边距（厘米）">
                  <el-input-number
                    v-model="printSetting.paddingBottom"
                    placeholder="下边距"
                    controls-position="right"
                    :precision="2"
                    :step="0.01"
                    @change="handlePrintSettingChange"
                  />
                </el-form-item>
              </el-form>
            </div>
          </el-collapse-item>
          <el-collapse-item
            v-if="selectedCellAxis && selectedCell"
            title="单元格设置"
            name="5"
          >
            <template slot="title">
              <div class="collapse-title">单元格设置</div>
            </template>
            <div class="collapse-content">
              <el-form label-position="top" label-width="80px" size="mini">
                <el-form-item label="单元格">
                  <el-input v-model="selectedCellAxis" readonly="readonly" />
                </el-form-item>
                <el-form-item label="单元格类型">
                  <el-select
                    v-model="selectedCell.type"
                    placeholder="请选择"
                    @change="handleCellTypeChange"
                  >
                    <el-option label="文本" value="text"> </el-option>
                    <el-option label="图片" value="image"> </el-option>
                    <el-option label="二维码" value="qrcode"> </el-option>
                    <el-option label="链接" value="link"> </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  v-if="
                    !selectedCell.type ||
                    selectedCell.type == 'text' ||
                    selectedCell.type == 'link'
                  "
                  label="文本"
                >
                  <el-input
                    v-model="selectedCell.text"
                    placeholder="文本"
                    @change="handleCellTextChange"
                  />
                </el-form-item>
                <el-form-item v-if="selectedCell.type == 'link'" label="链接">
                  <el-input
                    v-model="selectedCell.url"
                    placeholder="链接"
                    @change="handleCellUrlChange"
                  />
                </el-form-item>
                <el-form-item
                  v-if="selectedCell.type == 'qrcode'"
                  label="二维码"
                >
                  <el-input
                    v-model="selectedCell.url"
                    placeholder="二维码"
                    @change="handleCellUrlChange"
                  />
                </el-form-item>
                <el-form-item v-if="selectedCell.type == 'image'" label="图片">
                  <file-upload
                    v-model="selectedCell.url"
                    type="form"
                    title="单元格图片"
                    placeholder="图片"
                    accept="image/png,image/jpeg"
                    @change="handleCellUrlChange"
                  />
                </el-form-item>
                <el-form-item
                  v-if="
                    selectedCell.type == 'image' ||
                    selectedCell.type == 'qrcode'
                  "
                  :label="
                    selectedCell.type == 'image' ? '图片缩放' : '二维码缩放'
                  "
                >
                  <el-select
                    v-model="selectedCell.imageScale"
                    placeholder="请选择"
                    @change="handleCellImageScaleChange"
                  >
                    <!--<el-option
                      label="无"
                      value="">
                    </el-option>-->
                    <el-option label="填充" value="fill"> </el-option>
                    <!--<el-option
                      label="等比例填充"
                      value="cover">
                    </el-option>-->
                    <el-option label="自适应" value="contain"> </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="默认值">
                  <el-input
                    v-model="selectedCell.defaultValue"
                    placeholder="默认值"
                    @change="handleCellDefaultValueChange"
                  />
                </el-form-item>
                <el-form-item label="数据类型">
                  <el-select
                    v-model="selectedCell.dataType"
                    placeholder="请选择"
                    @change="handleCellDataTypeChange"
                  >
                    <el-option label="文本" value="string"> </el-option>
                    <el-option label="数值" value="number"> </el-option>
                    <el-option label="json" value="json"> </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  v-if="
                    !selectedCell.dataType ||
                    selectedCell.dataType == 'string' ||
                    selectedCell.dataType == 'number'
                  "
                  label="分割符"
                >
                  <el-input
                    v-model="selectedCell.splitString"
                    placeholder="默认值"
                    @change="handleCellSplitStringChange"
                  />
                </el-form-item>
                <el-form-item label="扩展方向">
                  <el-select
                    v-model="selectedCell.expandDirection"
                    placeholder="请选择"
                    @change="handleCellExpandDirectionChange"
                  >
                    <el-option label="无" value="0"> </el-option>
                    <el-option label="横向" value="1"> </el-option>
                    <el-option label="纵向" value="2"> </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="扩展方式">
                  <el-select
                    v-model="selectedCell.expandType"
                    placeholder="请选择"
                    @change="handleCellExpandTypeChange"
                  >
                    <el-option label="覆盖" value="0"> </el-option>
                    <el-option label="插入" value="1"> </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  v-if="!selectedCell.type || selectedCell.type == 'text'"
                  label="是否分组"
                >
                  <el-select
                    v-model="selectedCell.group"
                    placeholder="请选择"
                    @change="handleCellGroupChange"
                  >
                    <el-option label="是" value="1"> </el-option>
                    <el-option label="否" value="0"> </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  v-if="!selectedCell.type || selectedCell.type == 'text'"
                  label="是否交叉"
                >
                  <!--<div slot="label">
                    <el-tooltip class="item" effect="dark" content="用在交叉表中，配合扩展方向使用" placement="right-start">
                      <label class="el-form-item__label">是否交叉</label>
                    </el-tooltip>
                  </div>-->
                  <el-select
                    v-model="selectedCell.cross"
                    placeholder="请选择"
                    @change="handleCellCrossChange"
                  >
                    <el-option label="是" value="1"> </el-option>
                    <el-option label="否" value="0"> </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item
                  v-if="!selectedCell.type || selectedCell.type == 'text'"
                  label="计算方式"
                >
                  <el-select
                    v-model="selectedCell.formula"
                    placeholder="请选择"
                    @change="handleCellFormulaChange"
                  >
                    <el-option label="无" value=""> </el-option>
                    <el-option label="求和" value="SUM"> </el-option>
                    <el-option label="求平均值" value="AVERAGE"> </el-option>
                    <el-option label="求最大值" value="MAX"> </el-option>
                    <el-option label="求最小值" value="MIN"> </el-option>
                  </el-select>
                </el-form-item>
              </el-form>
            </div>
          </el-collapse-item>
        </el-collapse>
        <!-- <model-select ref="dataSourceTree" @change="handleDatasourceChange">
          <div></div>
        </model-select> -->
        <file-upload
          ref="uploadCellImage"
          type="dialog"
          title="单元格图片"
          placeholder="请输入图片地址或上传图片"
          accept="image/png,image/jpeg"
          @change="handleCellUrlChange"
        />
        <input
          ref="importFile"
          type="file"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          style="display: none"
          @change="handleImportFileChange"
        />
      </div>
      <div
        id="x-spreadsheet-design"
        style="margin-left: 250px"
        @drop="handleDrop"
        @dragover="handleDragOver"
      ></div>
    </div>
    <!-- 选择数据源 -->
    <el-dialog
      title="选择数据源"
      :visible.sync="seldatasourceDialogVisible"
      append-to-body
      width="1100px"
    >
      <div class="data-source">
        <directData-source
          v-if="datasourcetype == '1'"
          :openfrom="'formdesign'"
          @handleSelectModel="handleSelectModel"
        />
        <extractData-source
          v-if="datasourcetype == '2'"
          :openfrom="'formdesign'"
          @handleSelectModel="handleSelectModel"
        />
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Spreadsheet from "@/plugins/x-data-spreadsheet";
import zhCN from "@/plugins/x-data-spreadsheet/src/locale/zh-cn";
import { stringAt } from "@/plugins/x-data-spreadsheet/src/core/alphabet";
import { getModel } from "@/api/core/data/datasource/model";
import {
  getBiReport,
  editBiReport,
  exportBiReport,
  importBiReport,
} from "@/api/bi/bi_report";
import DirectDataSource from "@/views/core/data/datasource/model/direct";
import ExtractDataSource from "@/views/core/data/datasource/model/extract";

export default {
  name: "report",
  components: {
    DirectDataSource,
    ExtractDataSource,
  },
  data() {
    return {
      imgs: {
        //folder: require("@/assets/image/folder1.png"),
        table: require("@/assets/image/table.png"),
      },
      defaultProps: {
        children: "children",
        label: "label",
      },
      datasourceList: [],
      datasourceTree: [],
      paramList: [],
      template: {},
      backgroundImage: {
        url: "",
        width: undefined,
        height: undefined,
      },
      selectedCell: {
        text: "",
        url: undefined,
        imageScale: undefined,
        defaultValue: "",
        dataType: undefined,
        splitString: undefined,
        expandDirection: undefined,
        expandType: undefined,
        group: undefined,
        cross: undefined,
        formula: undefined,
      },
      selectedCellAxis: "",
      selectedCellPos: {
        ri: -1,
        ci: -1,
      },
      showPrintGrid: true,
      printSetting: {
        size: "A4",
        orientation: "portrait",
        paddingLeft: 1.91,
        paddingRight: 1.91,
        paddingTop: 2.54,
        paddingBottom: 2.54,
      },
      colWidth: 100,
      rowHeight: 25,
      colLen: 26,
      rowLen: 100,
      seldatasourceDialogVisible: false,
      datasourcetype: "",
    };
  },
  created() {
    document.title = "报表设计";
    Spreadsheet.locale("zh-cn", zhCN);
  },
  mounted() {
    window.addEventListener("resize", this.onWindowResize);
    this.onWindowResize();

    const id = this.$route.query["_"];
    getBiReport(id).then((response) => {
      if (response.code == 0) {
        this.template = response.data;
        this.init(response.data);
      }
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.onWindowResize);
  },
  methods: {
    onWindowResize() {
      document.getElementById("left-bar").style.height =
        document.documentElement.clientHeight + "px";
    },
    init(data) {
      document.title = "报表设计 - " + data.name;
      if (data.datasource_ids) {
        const datasourceIds = data.datasource_ids.split(",");
        this.loadDatasources(datasourceIds, 0, () => {
          this.datasourceTree = this.handleTree(
            this.datasourceList,
            "id",
            "pid"
          );
          console.log(this.datasourceTree)
        });
      }

      this.xs = new Spreadsheet("#x-spreadsheet-design", {
        mode: "edit",
        showToolbar: true,
        showSaveButton: true,
        showBottomBar: false,
        showGrid: true,
        showPrintGrid: this.showPrintGrid,
        showContextMenu: true,
        view: {
          height: () => document.documentElement.clientHeight,
          width: () => document.documentElement.clientWidth - 250,
        },
        row: {
          len: this.rowLen,
          height: this.rowHeight,
          minHeight: 12,
        },
        col: {
          len: this.colLen,
          width: this.colWidth,
          indexWidth: 60,
          minWidth: 12,
        },
        emptyImage: "/images/img.png",
        print: {
          ...this.printSetting,
        },
      })
        .change((cdata) => {
          console.log("change", cdata);
          this.colLen = cdata.cols.len;
          this.rowLen = cdata.rows.len;
        })
        .on("save", (cb) => {
          cb();
          const datasource_ids = [];
          for (let i = 0; i < this.datasourceList.length; i++) {
            const d = this.datasourceList[i];
            if (d.type != "1") {
              datasource_ids.push(d.id);
            }
          }
          editBiReport({
            ...data,
            content: JSON.stringify(this.xs.getData()),
            datasource_ids: datasource_ids.join(","),
          }).then((responce) => {
            if (responce.code != 0) {
              this.msgSuccess(responce.msg);
            }
          });
        })
        .on("toolbar_action", (action, value) => {
          if (action == "image") {
            this.$refs.uploadCellImage.open("");
          } else if (action == "preview") {
            this.handlePreview();
          } else if (action == "export") {
            if (value == "excel") {
              this.handleExportExcel();
            } else if (value == "pdf") {
              this.handleExportPdf();
            }
          } else if (action == "import") {
            this.$refs.importFile.value = "";
            this.$refs.importFile.click();
          }
        })
        .on("cell-selected", (cell, ri, ci) => {
          //console.log(cell)
          this.selectedCell = cell || {
            type: "",
            text: "",
            url: undefined,
            imageScale: undefined,
            defaultValue: "",
            dataType: undefined,
            splitString: undefined,
            expandDirection: undefined,
            expandType: undefined,
            group: undefined,
            cross: undefined,
            formula: undefined,
          };
          this.selectedCellAxis = stringAt(ci) + (ri + 1);
          this.selectedCellPos.ri = ri;
          this.selectedCellPos.ci = ci;
        });

      let xsdata = [];
      if (data.content) {
        xsdata = JSON.parse(data.content);
        if (xsdata && xsdata.length > 0) {
          const d = xsdata[0];
          if (d.backgroundImage) {
            this.backgroundImage = d.backgroundImage;
          }
          if (d.rows) {
            this.rowLen = d.rows.len;
            this.rowHeight = d.rows.height;
          }
          if (d.cols) {
            this.colLen = d.cols.len;
            this.colWidth = d.cols.width;
          }
        }
      } else {
        xsdata = [
          {
            name: "Sheet1",
            styles: [
              {
                bgcolor: "#fcfcfc",
                //textwrap: true,
                //color: "#900b09",
                border: {
                  top: ["thin", "#0366d6"],
                  bottom: ["thin", "#0366d6"],
                  right: ["thin", "#0366d6"],
                  left: ["thin", "#0366d6"],
                },
              },
            ],
          },
        ];
      }

      this.xs.loadData(xsdata);
    },
    handleDragStart(data) {
      this.dragData = data;
      event.dataTransfer.setData(
        "text",
        "#{" + data.parent.name + "." + data.name + "}"
      );
      //event.dataTransfer.effectAllowed = "copyMove";
      //event.dataTransfer.dropEffect = "copy";
    },
    handleDrop(e) {
      const cr = this.xs.datas[0].getCellRectByXY(e.offsetX, e.offsetY);
      if (cr.ri < 0 || cr.ci < 0) {
        return;
      }
      e.preventDefault();
      const data = e.dataTransfer.getData("text");
      if (this.dragHoverCell) {
        this.xs.datas[0].rows.setCell(
          this.dragHoverCell.ri,
          this.dragHoverCell.ci,
          { style: this.dragHoverCell.style },
          "format"
        );
        this.dragHoverCell = undefined;
      }
      let cell = this.xs.datas[0].rows.getCell(cr.ri, cr.ci);
      if (!cell) {
        this.xs.cellText(cr.ri, cr.ci, "");
        cell = this.xs.datas[0].rows.getCell(cr.ri, cr.ci);
      }

      this.xs.sheet.selector.set(cr.ri, cr.ci);
      this.xs.sheet.trigger("cell-selected", cell, cr.ri, cr.ci);

      if (this.dragData) {
        if (this.dragData.display_type) {
          this.xs.setCellProperty(
            cr.ri,
            cr.ci,
            "type",
            this.dragData.display_type
          );
          if (
            this.dragData.display_type == "image" ||
            this.dragData.display_type == "qrcode" ||
            this.dragData.display_type == "link"
          ) {
            this.xs.setCellProperty(cr.ri, cr.ci, "url", data);
            if (this.dragData.display_type == "qrcode") {
              this.xs.setCellProperty(cr.ri, cr.ci, "imageScale", "contain");
            }
            if (this.dragData.display_type == "link") {
              this.xs.cellText(cr.ri, cr.ci, data);
              this.xs.sheet.data.setSelectedCellAttr("color", "#0000ff");
              this.xs.sheet.data.setSelectedCellAttr("underline", true);
            }
          } else {
            this.xs.cellText(cr.ri, cr.ci, data);
          }
        } else {
          this.xs.cellText(cr.ri, cr.ci, data);
        }
        if (this.dragData.parse_type) {
          if (this.dragData.parse_type.indexOf("self_cycle") == 0) {
            this.xs.setCellProperty(cr.ri, cr.ci, "expandDirection", "2");
            if (this.dragData.parse_type.indexOf("self_cycle_") == 0) {
              const splitString = this.dragData.parse_type.replace(
                "self_cycle_",
                ""
              );
              if (splitString == "json") {
                this.xs.setCellProperty(cr.ri, cr.ci, "dataType", "json");
              } else {
                this.xs.setCellProperty(
                  cr.ri,
                  cr.ci,
                  "splitString",
                  splitString
                );
              }
            }
          }
        }
        this.xs.reRender();
      } else {
        if (cell && (cell.type == "image" || cell.type == "qrcode")) {
          this.xs.setCellProperty(cr.ri, cr.ci, "url", data);
          this.xs.reRender();
        } else {
          this.xs.cellText(cr.ri, cr.ci, data).reRender();
        }
      }
      this.dragData = undefined;
    },
    handleDragOver(e) {
      const cr = this.xs.datas[0].getCellRectByXY(e.offsetX, e.offsetY);
      if (cr.ri < 0 || cr.ci < 0) {
        if (this.dragHoverCell) {
          this.xs.datas[0].rows.setCell(
            this.dragHoverCell.ri,
            this.dragHoverCell.ci,
            { style: this.dragHoverCell.style },
            "format"
          );
          this.dragHoverCell = null;
          this.xs.reRender();
        }
        return;
      }
      e.preventDefault();

      const cell = this.xs.datas[0].rows.getCell(cr.ri, cr.ci);
      if (
        !this.dragHoverCell ||
        this.dragHoverCell.ri != cr.ri ||
        this.dragHoverCell.ci != cr.ci
      ) {
        if (this.dragHoverCell) {
          this.xs.datas[0].rows.setCell(
            this.dragHoverCell.ri,
            this.dragHoverCell.ci,
            { style: this.dragHoverCell.style },
            "format"
          );
          this.dragHoverCell = undefined;
        }

        this.dragHoverCell = {
          ri: cr.ri,
          ci: cr.ci,
          style: cell ? cell.style : undefined,
        };
        this.xs.datas[0].rows.setCell(cr.ri, cr.ci, { style: 0 }, "format");
        this.xs.reRender();
      }
    },
    dbclick(e) {
      if (e.children) {
        return;
      }
      this.xs.setSelectedCellText("#{" + e.parent.name + "." + e.name + "}");
      this.xs.reRender();
    },
    handleDropdownVisibleChange(e, data) {
      this.$refs.tree.setCurrentNode(data);
    },
    handleCommand(cmd) {
      if (cmd.cmd === "refresh") {
        this.handleRefreshDatasource(cmd.item);
      } else if (cmd.cmd === "delete") {
        this.handleRemoveDatasource(cmd.item);
      } else if (cmd.cmd === "realTimeModel") {
        this.handleRealTimeDatasource(cmd.item);
      } else if (cmd.cmd === "aextractModel") {
        this.handleAextractModelDatasource(cmd.item);
      }
    },
    makeCommand(cmd, item) {
      return {
        cmd: cmd,
        item: item,
      };
    },
    handleRefreshDatasource(data) {
      this.loadDatasource(data.id, () => {
        this.datasourceTree = this.handleTree(this.datasourceList, "id", "pid");
      });
    },
    // 实时数据
    handleRealTimeDatasource(item) {
      this.datasourcetype = "1";
      this.seldatasourceDialogVisible = true;
    },
    // 抽取数据
    handleAextractModelDatasource(item) {
      this.datasourcetype = "2";
      this.seldatasourceDialogVisible = true;
    },
    // 实时 抽取 确定
    handleSelectModel(data) {
      let ids = [];
      ids.push(data.id);
      this.loadDatasources(ids, 0, () => {
        this.datasourceTree = this.handleTree(this.datasourceList, "id", "pid");
      });
      this.seldatasourceDialogVisible = false;
    },

    loadDatasources(ids, index, callback) {
      if (index >= ids.length) {
        if (callback) {
          callback();
        }
        return;
      }
      this.loadDatasource(parseInt(ids[index]), () => {
        this.loadDatasources(ids, index + 1, callback);
      });
    },
    loadDatasource(id, callback) {
      if (this.loadingDatasource) {
        if (callback) {
          callback();
        }
        return;
      }

      this.loadingDatasource = true;
      getModel(id).then((response) => {
        this.loadingDatasource = false;
        if (response.code == 0) {
          const data = response.data;
          data.label = data.name;
          data.children = [];
          if (data.metas) {
            for (let i = 0; i < data.metas.length; i++) {
              const m = data.metas[i];
              data.children.push({
                id: m.id,
                name: m.name,
                type: m.type,
                parse_type: m.parse_type,
                display_type: m.display_type,
                label: m.name + (m.label ? "(" + m.label + ")" : ""),
                parent: {
                  id: data.id,
                  name: data.name,
                  label: data.label,
                },
              });
            }
          }
          if (data.params) {
            for (let i = 0; i < data.params.length; i++) {
              const p = data.params[i];
              let exist = false;
              for (let j = 0; j < this.paramList.length; j++) {
                if (this.paramList[j].name == p.name) {
                  exist = true;
                  break;
                }
              }
              if (!exist) {
                this.paramList.push({
                  id: p.id,
                  name: p.name,
                  label:
                    p.name + (p.default_value ? "=" + p.default_value : ""),
                });
              }
            }
          }

          let index = -1;
          for (let i = 0; i < this.datasourceList.length; i++) {
            if (this.datasourceList[i].id == id) {
              index = i;
              break;
            }
          }
          if (index >= 0) {
            this.datasourceList.splice(index, 1, data);
          } else {
            this.datasourceList.push(data);
          }

          if (data.pid > 0) {
            let index = -1;
            for (let i = 0; i < this.datasourceList.length; i++) {
              if (this.datasourceList[i].id == data.pid) {
                index = i;
                break;
              }
            }
            if (index < 0) {
              this.loadDatasource(data.pid, callback);
              return;
            }
          }
        }
        if (callback) {
          callback();
        }
      });
    },
    handleRemoveDatasource(data) {
      this.$confirm('确定移除名称为"' + data.name + '"的数据集?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let index = -1;
          for (let i = 0; i < this.datasourceList.length; i++) {
            if (this.datasourceList[i].id == data.id) {
              index = i;
              break;
            }
          }
          if (index >= 0) {
            this.datasourceList.splice(index, 1);
            this.datasourceTree = this.handleTree(
              this.datasourceList,
              "id",
              "pid"
            );
          }
        })
        .catch(function () {});
    },
    handlePreview() {
      window.open("bi.html#/report/view?_=" + this.$route.query["_"]);
    },
    handleExportExcel() {
      exportBiReport(
        this.$route.query["_"],
        JSON.stringify(this.$route.query),
        "xlsx"
      );
    },
    handleExportPdf() {
      exportBiReport(
        this.$route.query["_"],
        JSON.stringify(this.$route.query),
        "pdf"
      );
    },
    handleBackgroundImageChange() {
      this.xs.setBackgroundImage(this.backgroundImage);
      this.xs.reRender();
    },
    handleColWidthChange() {
      this.xs.setDefaultColWidth(this.colWidth);
      this.xs.reRender();
    },
    handleRowHeightChange() {
      this.xs.setDefaultRowHeight(this.rowHeight);
      this.xs.reRender();
    },
    handleColLenChange() {
      this.xs.setDefaultColLen(this.colLen);
      this.xs.reRender();
    },
    handleRowLenChange() {
      this.xs.setDefaultRowLen(this.rowLen);
      this.xs.reRender();
    },
    handleCellUrlChange(url) {
      if (this.selectedCellPos.ri < 0 || this.selectedCellPos.ci < 0) {
        return;
      }
      if (url) {
        if (
          this.selectedCell.type != "image" &&
          this.selectedCell.type != "qrcode" &&
          this.selectedCell.type != "link"
        ) {
          this.selectedCell.type = "image";
        }
        this.selectedCell.url = url;
      }
      this.xs.setCellProperty(
        this.selectedCellPos.ri,
        this.selectedCellPos.ci,
        "type",
        this.selectedCell.type
      );
      this.xs.setCellProperty(
        this.selectedCellPos.ri,
        this.selectedCellPos.ci,
        "url",
        this.selectedCell.url
      );
      this.xs.reRender();
    },
    handleCellImageScaleChange() {
      if (this.selectedCellPos.ri < 0 || this.selectedCellPos.ci < 0) {
        return;
      }
      this.xs.setCellProperty(
        this.selectedCellPos.ri,
        this.selectedCellPos.ci,
        "imageScale",
        this.selectedCell.imageScale
      );
      this.xs.reRender();
    },
    handleCellTypeChange() {
      if (this.selectedCellPos.ri < 0 || this.selectedCellPos.ci < 0) {
        return;
      }
      this.xs.setCellProperty(
        this.selectedCellPos.ri,
        this.selectedCellPos.ci,
        "type",
        this.selectedCell.type
      );
      /*if (this.selectedCell.type == 'link') {
        this.xs.sheet.data.setSelectedCellAttr('color', '#0000ff')
        this.xs.sheet.data.setSelectedCellAttr('underline', true)
      }*/
      this.xs.reRender();
    },
    handleCellTextChange() {
      if (this.selectedCellPos.ri < 0 || this.selectedCellPos.ci < 0) {
        return;
      }
      this.xs.cellText(
        this.selectedCellPos.ri,
        this.selectedCellPos.ci,
        this.selectedCell.text
      );
      this.xs.reRender();
    },
    handleCellDefaultValueChange() {
      if (this.selectedCellPos.ri < 0 || this.selectedCellPos.ci < 0) {
        return;
      }
      this.xs.setCellProperty(
        this.selectedCellPos.ri,
        this.selectedCellPos.ci,
        "defaultValue",
        this.selectedCell.defaultValue
      );
      this.xs.reRender();
    },
    handleCellDataTypeChange() {
      if (this.selectedCellPos.ri < 0 || this.selectedCellPos.ci < 0) {
        return;
      }
      this.xs.setCellProperty(
        this.selectedCellPos.ri,
        this.selectedCellPos.ci,
        "dataType",
        this.selectedCell.dataType
      );
      this.xs.reRender();
    },
    handleCellSplitStringChange() {
      if (this.selectedCellPos.ri < 0 || this.selectedCellPos.ci < 0) {
        return;
      }
      this.xs.setCellProperty(
        this.selectedCellPos.ri,
        this.selectedCellPos.ci,
        "splitString",
        this.selectedCell.splitString
      );
      this.xs.reRender();
    },
    handleCellExpandDirectionChange() {
      if (this.selectedCellPos.ri < 0 || this.selectedCellPos.ci < 0) {
        return;
      }
      this.xs.setCellProperty(
        this.selectedCellPos.ri,
        this.selectedCellPos.ci,
        "expandDirection",
        this.selectedCell.expandDirection
      );
      this.xs.reRender();
    },
    handleCellExpandTypeChange() {
      if (this.selectedCellPos.ri < 0 || this.selectedCellPos.ci < 0) {
        return;
      }
      this.xs.setCellProperty(
        this.selectedCellPos.ri,
        this.selectedCellPos.ci,
        "expandType",
        this.selectedCell.expandType
      );
      this.xs.reRender();
    },
    handleCellGroupChange() {
      if (this.selectedCellPos.ri < 0 || this.selectedCellPos.ci < 0) {
        return;
      }
      this.xs.setCellProperty(
        this.selectedCellPos.ri,
        this.selectedCellPos.ci,
        "group",
        this.selectedCell.group
      );
      this.xs.reRender();
    },
    handleCellCrossChange() {
      if (this.selectedCellPos.ri < 0 || this.selectedCellPos.ci < 0) {
        return;
      }
      this.xs.setCellProperty(
        this.selectedCellPos.ri,
        this.selectedCellPos.ci,
        "cross",
        this.selectedCell.cross
      );
      this.xs.reRender();
    },
    handleCellFormulaChange() {
      if (this.selectedCellPos.ri < 0 || this.selectedCellPos.ci < 0) {
        return;
      }
      this.xs.setCellProperty(
        this.selectedCellPos.ri,
        this.selectedCellPos.ci,
        "formula",
        this.selectedCell.formula
      );
      this.xs.reRender();
    },
    handlePrintSettingChange() {
      this.xs.setPrintSetting(this.printSetting);
      this.xs.reRender();
    },
    handleShowPrintGridChange() {
      this.xs.setShowPrintGrid(this.showPrintGrid);
      this.xs.reRender();
    },
    handleImportFileChange() {
      if (this.$refs.importFile.files.length > 0) {
        const file = this.$refs.importFile.files[0];
        let formData = new FormData();
        formData.append("file", file);
        importBiReport(formData).then((response) => {
          console.log(response);
          this.xs.loadData(response.data);
          this.xs.notifyChange();
        });
      }
    },
  },
  watch: {
    $route(to, from) {
      if (to.path == from.path) {
        window.location.reload();
      }
    },
  },
};
</script>
<style scoped>
::-webkit-scrollbar {
  width: 8px;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
  border-radius: 8px;
}
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.2);
}
.tree-node {
  font-size: 14px;
}
.tree-node-leaf {
  -webkit-user-drag: element;
  user-select: none;
}
.tree-node-root {
  user-select: none;
}
.custom-tree-node {
  width: 100%;
  display: flex;
  align-items: center;
  height: 26px;
  padding-right: 5px;
}
.custom-tree-node-img {
  width: 20px;
  height: 20px;
  margin-right: 6px;
}
.custom-tree-node-label {
  flex: 1;
  width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}
.custom-tree-node-button {
  display: none;
}
.custom-tree-node:hover .custom-tree-node-button {
  display: inline-block;
}
.collapse-title {
  margin-left: 10px;
}
.collapse-content {
  padding-left: 10px;
  padding-right: 10px;
}
.mini-button {
  margin-left: 0px;
  padding: 3px;
}
/*.el-input-number {
  width: 100%;
}*/
</style>
